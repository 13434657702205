<template>
    <v-container
        fluid
        class="ma-0 py-5 px-3">
        <video
            controls
            src="https://eu-central-1.linodeobjects.com/gamecam-bucket/padel--1117697424.mp4"
            style="width: 100%"></video>

        <v-range-slider
            class="mx-3"
            max="100"
            min="0"
            v-model="range"
            @change="rangeUpdated"></v-range-slider>
        <v-row :key="key">
            <v-col cols="12">
                <h3 class="text-h5 mb-3">Distance traveled</h3>
                <div class="text-h3 font-weight-light ma-5">{{ haveDist() ? formatDist(datas[selectedPlayer].dist) : '_' }}</div>
            </v-col>
            <v-col
                cols="6"
                md="3">
                <h3
                    class="text-h5 mb-3"
                    style="position: relative">
                    Heatmap
                    <v-progress-linear
                        class="mt-5 loaders"
                        v-if="!haveHeatmap() || loading"
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"></v-progress-linear>
                </h3>
                <v-img
                    style="filter: brightness(2)"
                    class="heatmaps"
                    width="100%"
                    height="-webkit-fill-available"
                    eager
                    v-if="haveHeatmap()"
                    :src="datas[selectedPlayer].heat_map"></v-img>
            </v-col>
            <v-col
                cols="6"
                md="3">
                <h3 class="text-h5 mb-3">Zonemap</h3>
                <div class="court-zone-cnt">
                    <div class="court-zone court-grid row-centered">
                        <span>{{ haveZonemap() ? formatPerc(datas[selectedPlayer].zone_map[0]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-mid row-centered">
                        <span>{{ haveZonemap() ? formatPerc(datas[selectedPlayer].zone_map[1]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-net row-centered">
                        <span>{{ haveZonemap() ? formatPerc(datas[selectedPlayer].zone_map[2]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-net row-centered">
                        <span>{{ haveZonemap() ? formatPerc(datas[selectedPlayer].zone_map[3]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-mid row-centered">
                        <span>{{ haveZonemap() ? formatPerc(datas[selectedPlayer].zone_map[4]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-grid row-centered">
                        <span>{{ haveZonemap() ? formatPerc(datas[selectedPlayer].zone_map[5]) : '_' }}</span>
                    </div>
                </div>
            </v-col>

            <v-col
                cols="6"
                md="3">
                <h3
                    class="text-h5 mb-3"
                    style="position: relative">
                    Ball Landings
                    <v-progress-linear
                        class="mt-5 loaders"
                        v-if="!haveBallmap() || loading"
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"></v-progress-linear>
                </h3>
                <v-img
                    style="filter: brightness(2)"
                    class="heatmaps"
                    width="100%"
                    height="-webkit-fill-available"
                    eager
                    v-if="haveBallmap()"
                    :src="datas[selectedPlayer].ball_map"></v-img>
            </v-col>
            <v-col
                cols="6"
                md="3">
                <h3 class="text-h5 mb-3">Ball Zonemap</h3>
                <div class="court-zone-cnt">
                    <div class="court-zone court-grid row-centered">
                        <span>{{ haveBallZonemap() ? formatPerc(datas[selectedPlayer].ball_zone_map[0]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-mid row-centered">
                        <span>{{ haveBallZonemap() ? formatPerc(datas[selectedPlayer].ball_zone_map[1]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-net row-centered">
                        <span>{{ haveBallZonemap() ? formatPerc(datas[selectedPlayer].ball_zone_map[2]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-net row-centered">
                        <span>{{ haveBallZonemap() ? formatPerc(datas[selectedPlayer].ball_zone_map[3]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-mid row-centered">
                        <span>{{ haveBallZonemap() ? formatPerc(datas[selectedPlayer].ball_zone_map[4]) : '_' }}</span>
                    </div>
                    <div class="court-zone court-grid row-centered">
                        <span>{{ haveBallZonemap() ? formatPerc(datas[selectedPlayer].ball_zone_map[5]) : '_' }}</span>
                    </div>
                </div>
            </v-col>
            <v-col cols="12">
                <h3 class="text-h5 mb-3">Errors</h3>
                <div class="text-h3 font-weight-light ma-5">Net shot : {{ haveNet() ? datas[selectedPlayer].net : '_' }}</div>
                <div class="text-h3 font-weight-light ma-5">Out shot : {{ haveOut() ? datas[selectedPlayer].out : '_' }}</div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
//const ip = '127.0.0.1';
const ip = '34.152.28.144';
const port = '3003';

const api = `http://${ip}:${port}/`;
export default {
    name: 'Home',
    watch: {},
    mounted() {
        this.getDatas(true, true);
    },
    beforeDestroy() {},
    methods: {
        rangeUpdated() {
            // Snap to 0 and 100
            if (this.range[0] < 5) {
                this.range = [0, this.range[1]];
            }
            if (this.range[0] > 90) {
                this.range = [90, this.range[1]];
            }
            if (this.range[1] - this.range[0] < 10) {
                this.range = [this.range[0], this.range[0] + 10];
            }
            if (this.range[1] > 95) {
                this.range = [this.range[0], 100];
            }

            this.startFrame = this.range[0] / 100;
            this.endFrame = this.range[1] / 100;
            console.log(this.range);
            this.getDatas(true);
        },
        async getDatas(mkQuery = false, recall = false) {
            const url = api + 'data';
            const request = { startFrame: this.startFrame, endFrame: this.endFrame };
            try {
                if (mkQuery || this.endFrame == 1) {
                    this.loading = true;
                    const res = await axios({
                        method: 'post',
                        url: url,
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        data: JSON.stringify(request),
                        timeout: 20000,
                    });
                    this.loading = false;
                    console.log(typeof res.data);
                    if (typeof res.data === 'string') {
                        const results = JSON.parse(res.data.replaceAll('NaN', 0));
                        results.success && this.updateResults(results.datas);
                    } else {
                        res.data.success && this.updateResults(res.data.datas);
                    }
                    console.log('datas = ', this.datas);
                }
            } catch (e) {
                console.error(e);
            } finally {
                recall &&
                    setTimeout(() => {
                        this.getDatas(false, true);
                    }, 30000);
            }
        },
        updateResults(d) {
            try {
                let datas = {};
                console.log(typeof d);
                console.log('updating from :', d);
                if (typeof d === 'string') {
                    datas = JSON.parse(d);
                } else {
                    datas = d;
                }
                if ('player_data' in datas) {
                    for (const key in datas.player_data) {
                        console.log(`player_data[${key}] = ${datas.player_data[key]}`);
                        if (!Object.hasOwnProperty.call(datas.player_data, key)) {
                            continue;
                        }

                        this.checkKey(key);
                        const p_data = datas.player_data[key];
                        for (const k in p_data) {
                            if (!Object.hasOwnProperty.call(p_data, k)) {
                                continue;
                            }
                            this.datas[key][k] = p_data[k];
                        }
                    }
                }
                if ('ball_player_data' in datas) {
                    for (const key in datas.ball_player_data) {
                        console.log(`ball_player_data[${key}] = ${datas.ball_player_data[key]}`);
                        if (!Object.hasOwnProperty.call(datas.ball_player_data, key)) {
                            continue;
                        }
                        this.checkKey(key);
                        const p_data = datas.ball_player_data[key];
                        for (const k in p_data) {
                            if (!Object.hasOwnProperty.call(p_data, k)) {
                                continue;
                            }
                            this.datas[key][k] = p_data[k];
                        }
                    }
                }
                this.checkKey(-1);
                if ('data' in datas) {
                    this.datas['-1']['heat_map'] = datas.data;
                }
                if ('ball_data' in datas) {
                    this.datas['-1']['ball_map'] = datas.ball_data;
                }
                if ('zone_data' in datas) {
                    this.datas['-1']['zone_map'] = datas.zone_data;
                }
                if ('ball_zone_data' in datas) {
                    this.datas['-1']['ball_zone_map'] = datas.ball_zone_data;
                }
                this.key++;
            } catch (e) {
                console.error(e);
            }
        },
        formatPerc(arg) {
            const n = Number(arg);
            if (isNaN(n)) return '_';
            return `${n.toFixed(2)} %`;
        },
        formatDist(arg) {
            const n = Number(arg);
            if (isNaN(n)) return '_';
            return `${n.toFixed(2)} m`;
        },
        checkKey(key) {
            const k = `${key}`;
            if (!(k in this.datas)) {
                this.datas[k] = {};
            }
        },
        haveDist() {
            return this.selectedPlayer in this.datas && 'dist' in this.datas[this.selectedPlayer];
        },
        haveHeatmap() {
            return this.selectedPlayer in this.datas && 'heat_map' in this.datas[this.selectedPlayer];
        },
        haveBallmap() {
            return this.selectedPlayer in this.datas && 'ball_map' in this.datas[this.selectedPlayer];
        },
        haveZonemap() {
            return this.selectedPlayer in this.datas && 'zone_map' in this.datas[this.selectedPlayer];
        },
        haveBallZonemap() {
            return this.selectedPlayer in this.datas && 'ball_zone_map' in this.datas[this.selectedPlayer];
        },
        haveNet() {
            return this.selectedPlayer in this.datas && 'net' in this.datas[this.selectedPlayer];
        },
        haveOut() {
            return this.selectedPlayer in this.datas && 'net' in this.datas[this.selectedPlayer];
        },
    },
    data: () => ({
        loading: false,
        key: 0,
        range: [0, 100],
        startFrame: 0,
        endFrame: 1,
        datas: {},
    }),
    components: {},
    props: {
        selectedPlayer: {
            type: String,
            default: () => {
                return '-1';
            },
            required: false,
        },
    },
};
</script>
<style scoped>
.court-zone-cnt {
    width: -webkit-fill-available;
    aspect-ratio: 0.5;
    margin: 5%;
}
.court-zone {
    border: 2px solid #fff;
    color: #fff;
    height: 16.6%;
}
.court-grid {
    background-color: #ff980040;
}
.court-mid {
    background-color: #cddc3940;
}
.court-net {
    background-color: #4caf5040;
}
.loaders {
    position: absolute;
    bottom: 0px;
    z-index: 5;
}
</style>
