<template>
    <v-app>
        <v-app-bar
            app
            color="grey darken-4"
            dark>
            Heatmap
            <v-spacer></v-spacer>
            <div class="app-bar-cnt row-centered">
                <v-select
                    hide-details
                    solo
                    dense
                    v-model="selectedPlayer"
                    :items="players"></v-select>
            </div>
        </v-app-bar>

        <v-main>
            <router-view :selectedPlayer="selectedPlayer" />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        players: [
            { text: 'All', value: '-1' },
            { text: 'Player 1', value: '0' },
            { text: 'Player 2', value: '1' },
            { text: 'Player 3', value: '2' },
            { text: 'Player 4', value: '3' },
        ],
        selectedPlayer: '0',
        //
    }),
};
</script>
<style>
.row-centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.app-bar-cnt {
    height: 100%;
}
.v-select > * {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #272727;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--v-primary-base);
}
</style>
